import { createContext, useContext, useEffect, useReducer } from "react";
import ProductReducer from "../Reducer/ProductReducer";
import axios from "axios";

const ProductContext = createContext();

const cart = JSON.parse(localStorage.getItem("cart"));

const initialState = {
  Products: [],
  modalProduct: [],
  singleProduct: [],
  ID: null,
  cartProducts: cart && cart?.length > 0 ? cart : [],
  wishList: [],
  overflow: false,
  isCartAdded: false,
  isWishAdded: false,
  isAnimated: false,
  modal: false,
  counter: 1,
};

const ProductProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProductReducer, initialState);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get("http://localhost:4000/product");
        dispatch({ type: "GET_PRODUCTS", payload: response?.data });
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const showModal = (_id) => {
    dispatch({ type: "MODAL", productId: _id });
  };

  const modalInc = (_id) => {
    dispatch({ type: "MODAL_AMOUNT_INC", productId: _id });
  };

  const modalDec = (_id) => {
    dispatch({ type: "MODAL_AMOUNT_DEC", productId: _id });
  };

  const addToSinglePro = (product) => {
    dispatch({ type: "ADD_TO_SINGLE", payload: product });
  };

  const singleInc = (_id) => {
    dispatch({ type: "SINGLE_INC", productId: _id });
  };

  const singleDec = (_id) => {
    dispatch({ type: "SINGLE_DEC", productId: _id });
  };

  const closeModal = () => {
    dispatch({ type: "FADE" });
  };

  const addToCart = (_id, info, img, prize, stock) => {
    dispatch({
      type: "ADD_TO_CART",
      payload: { _id, info, img, prize, stock },
    });
  };

  const removeItem = (_id) => {
    dispatch({ type: "REMOVE_FROM_CART", productId: _id });
  };

  const addToWish = (wishId, wishInfo, wishImg, wishPrize, wishStock) => {
    dispatch({
      type: "ADD_TO_WISHLIST",
      payload: { wishId, wishInfo, wishImg, wishPrize, wishStock },
    });
  };

  const animated = () => {
    dispatch({ type: "ANIMATED" });
  };

  const UnAnimated = () => {
    dispatch({ type: "UNANIMATED" });
  };

  const increment = (_id) => {
    dispatch({ type: "INCREMENT", productId: _id });
  };

  const decrement = (_id) => {
    dispatch({ type: "DECREMENT", productId: _id });
  };

  return (
    <ProductContext.Provider
      value={{
        ...state,
        showModal,
        closeModal,
        addToCart,
        removeItem,
        animated,
        UnAnimated,
        increment,
        decrement,
        addToSinglePro,
        addToWish,
        modalInc,
        modalDec,
        singleInc,
        singleDec,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

// custom hook

const UseProductContext = () => {
  return useContext(ProductContext);
};

export { ProductContext, ProductProvider, UseProductContext };
