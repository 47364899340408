import React, { useState } from "react";
import SectionHero from "../SectionHero";
import { v4 as uuid } from "uuid";
import { RiImageAddFill } from "react-icons/ri";
import { HiXMark } from "react-icons/hi2";
import { displayCategory, productCategory } from "../../Data/ProductsData";
import { useFormik } from "formik";
import axios from "axios";

const AdminPanel = () => {
  const [uploadImg, setUploadImg] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const cloudName = "dxtdndwef";
  const unsignedUploadPreset = "qpd7snkj";

  const { handleChange, handleSubmit, values, errors, setErrors } = useFormik({
    initialValues: {
      name: "",
      category: "",
      displayCategory: "",
      prize: "",
      oldPrize: "",
      option: "",
      discount: "",
      stock: "",
      description: "",
    },

    onSubmit: async (values, { resetForm }) => {
      // Check if images are uploaded
      if (uploadImg.length === 0) {
        setErrors({ img: "Please upload at least one image." });
        setFormSubmitted(true);
        return;
      }

      try {
        // Upload images to Cloudinary
        const imageUrls = await Promise.all(uploadImg.map(uploadToCloudinary));

        // Send FormData to API endpoint
        const formData = {
          ...values,
          images: imageUrls,
        };

        const response = await axios.post(
          "http://localhost:4000/product",
          formData
        );

        console.log("Product data submitted:", response.data);
        setImagePreviews([]);
        setUploadImg([]);
        resetForm();
      } catch (error) {
        console.error("Error uploading product:", error);
      }
    },
  });

  const uploadToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", unsignedUploadPreset);

    const response = await axios.post(
      `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
      formData
    );

    return response.data.secure_url;
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    // Update image previews
    const previews = files.map((file) => ({
      imgName: uuid() + file.name,
      url: URL.createObjectURL(file),
    }));

    setImagePreviews((prev) => [...prev, ...previews]);
    setUploadImg((prev) => [...prev, ...files]);

    if (formSubmitted && files.length > 0) {
      setErrors({ img: "" });
      setFormSubmitted(false);
    }
  };

  const deleteImage = (imgName) => {
    // Remove image preview
    setImagePreviews((prev) =>
      prev.filter((preview) => preview.imgName !== imgName)
    );

    // Remove image file from uploadImg array
    setUploadImg((prev) => prev.filter((file) => !file.name.includes(imgName)));

    // Clear error message when user deletes an image
    if (formSubmitted && uploadImg.length === 1) {
      setErrors({ img: "" });
      setFormSubmitted(false);
    }
  };

  return (
    <div className="admin-panel">
      <SectionHero heading={"Admin Panel"} />
      <div className="admin-panel-form">
        <div className="container">
          <h2>Add Products</h2>
          <form
            className="add product"
            onSubmit={(e) => {
              handleSubmit(e);
              e.target.reset();
            }}
          >
            {/* Image upload section */}
            <div className="file-container">
              <label htmlFor="img" className="file-img">
                <RiImageAddFill />
              </label>
              <input
                type="file"
                name="img"
                id="img"
                onChange={handleImageChange}
                multiple
                accept="image/*"
              />
            </div>
            <div className="upload-img-container">
              {/* Display uploaded images */}
              {imagePreviews.map((preview) => (
                <div key={preview.imgName}>
                  <img src={preview.url} alt="product-img" />
                  <div
                    className="icon"
                    onClick={() => deleteImage(preview.imgName)}
                  >
                    <HiXMark />
                  </div>
                </div>
              ))}
            </div>

            {/* Display error message for image upload */}
            {errors.img && <div className="error-message">{errors.img}</div>}

            {/* Product form fields */}
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              name="name"
              id="name"
              value={values.name}
              onChange={handleChange}
            />

            <label htmlFor="category">Category:</label>
            <select
              name="category"
              id="category"
              value={values.category}
              onChange={handleChange}
            >
              <option value="">Select a Category</option>
              {productCategory.map((item) => (
                <option key={item}>{item}</option>
              ))}
            </select>

            <label htmlFor="displayCategory">Where to Display product:</label>
            <div className="displayCategory">
              {displayCategory.map((category) => (
                <div className="item" key={category}>
                  <label htmlFor={category}>{category}</label>
                  <input
                    type="checkbox"
                    name="displayCategory"
                    value={category}
                    id={category}
                    onChange={handleChange}
                  />
                </div>
              ))}
            </div>

            <label htmlFor="prize">Product Prize:</label>
            <input
              type="number"
              name="prize"
              id="prize"
              value={values.prize}
              min={0}
              onChange={handleChange}
            />

            <label htmlFor="oldPrize">Old Prize:</label>
            <input
              type="number"
              name="oldPrize"
              id="oldPrize"
              value={values.oldPrize}
              min={0}
              onChange={handleChange}
            />

            <label htmlFor="discount">Discount(%):</label>
            <input
              type="number"
              name="discount"
              id="discount"
              value={values.discount}
              min={0}
              onChange={handleChange}
            />

            <label htmlFor="stock">Stock:</label>
            <input
              type="number"
              name="stock"
              id="stock"
              min={0}
              value={values.stock}
              onChange={handleChange}
            />

            <label htmlFor="description">Description:</label>
            <textarea
              name="description"
              id="description"
              cols="30"
              rows="10"
              value={values.description}
              onChange={handleChange}
            ></textarea>

            <button type="submit">Add Product</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
