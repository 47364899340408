import { news1, news2, news3 } from "../Components/images/img";

export const newsData = [
  {
    img: news1,
    title: "EIUMOD TEMPOR INCIDIDUNT LABORE...",
    user: "Akash",
    date: "22 september, 2021",
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo iusto odit non fugiat. Autem reiciendis dolore vero porro, et doloribus.`,
  },
  {
    img: news2,
    title: "EIUMOD TEMPOR INCIDIDUNT LABORE...",
    user: "Akash",
    date: "22 september, 2021",
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo iusto odit non fugiat. Autem reiciendis dolore vero porro, et doloribus.`,
  },
  {
    img: news3,
    title: "EIUMOD TEMPOR INCIDIDUNT LABORE...",
    user: "Akash",
    date: "22 september, 2021",
    text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo iusto odit non fugiat. Autem reiciendis dolore vero porro, et doloribus.`,
  },
];
