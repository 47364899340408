/* eslint-disable eqeqeq */
import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { UseProductContext } from "./Components/Context/ProductsContext.js";
import { AnimatePresence } from "framer-motion";
import "./sass/style.scss";
import AsideNav from "./Components/Aside/AsideNav.js";
import AsideSearch from "./Components/Aside/AsideSearch.js";
import AsideSetting from "./Components/Aside/AsideSetting.js";
import BottomNav from "./Components/BottomNav.js";
import CartButton from "./Components/CartButton.js";
import Footer from "./Components/Footer.js";
import Modal from "./Components/Modal.js";
import Header from "./Components/Header/Header.js";
import Home from "./Components/pages/Home/Home.js";
import AsideCart from "./Components/Aside/AsideCart.js";
import Collection from "./Components/pages/Collection.js";
import Contact from "./Components/pages/Contact.js";
import Error from "./Components/pages/Error.js";
import CartPage from "./Components/pages/CartPage.js";
import About from "./Components/pages/About/About.js";
import Product from "./Components/pages/Product.js";
import Signin from "./Components/pages/Signin.js";
import WishList from "./Components/pages/WishList.js";
import AdminPanel from "./Components/pages/AdminPanel.js";
import SignUp from "./Components/pages/SignUp.js";

function App() {
  const { pathname } = useLocation();
  const { overflow } = UseProductContext();

  const loginData = localStorage.getItem("userData");

  document.body.style.overflow = overflow ? "hidden" : "auto";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <AnimatePresence>
      <div className="App">
        <Header />
        <AsideNav />
        <AsideSearch />
        <AsideSetting />
        <AsideCart />
        <CartButton />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="collection/:category" element={<Collection />} />
            <Route path="contact" element={<Contact />} />
            <Route path="cart" element={<CartPage />} />
            <Route path="product/:id" element={<Product />} />
            <Route path="signin" element={<Signin />} />
            <Route path="signUp" element={<SignUp />} />
            <Route path="wishlist" element={<WishList />} />
            <Route path="*" element={<Error />} />
            {JSON.parse(loginData)?.role == "ADMIN" && (
              <Route path="admin-panel" element={<AdminPanel />} />
            )}
          </Routes>
        </main>
        <BottomNav />
        <Footer />
        <Modal />
      </div>
    </AnimatePresence>
  );
}

export default App;
