import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SectionHero from "../SectionHero";
import axios from "axios";
import { BsArrowRepeat } from "react-icons/bs";

const SignUp = () => {
  const recaptchaContainerRef = useRef(null);
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [verificationId, setVerificationId] = useState(null);
  const [generatedOtp, setGeneratedOtp] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      navigate("/");
    }
  }, [navigate]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    const userData = localStorage.getItem("userData");
    if (userData) {
      setError("User already signed up.");
      return;
    }

    try {
      const response = await axios.post("http://localhost:4000/auth/sendotp", {
        mobileNumber: `+91${mobileNumber}`,
      });

      if (response.data.success) {
        setVerificationId(response.data.verificationId);
        setGeneratedOtp(response.data.otp);
        // alert(`Your OTP is: ${response.data.otp}`);
      } else {
        setError("Error sending OTP");
        setGeneratedOtp(null);
      }
    } catch (error) {
      setError("Error sending OTP: " + error.message);
      console.error("Error sending OTP: ", error);
    }
  };

  const handleOtpResend = async (e) => {
    e.preventDefault();
    const userData = localStorage.getItem("userData");
    if (userData) {
      setError("User already signed up.");
      return;
    }

    try {
      const response = await axios.post(
        "http://localhost:4000/auth/resendotp",
        {
          mobileNumber: `+91${mobileNumber}`,
        }
      );

      if (response.data.success) {
        setVerificationId(response.data.verificationId);
        setGeneratedOtp(response.data.otp);
        // alert(`Your OTP is: ${response.data.otp}`);
      } else {
        setError("Error sending OTP");
        setGeneratedOtp(null);
      }
    } catch (error) {
      setError("Error sending OTP: " + error.message);
      console.error("Error sending OTP: ", error);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:4000/auth/verifyotp",
        {
          otp,
          verificationId,
        }
      );

      if (response.data.success) {
        const user = response.data.user;
        localStorage.setItem("userData", JSON.stringify(user));
        setGeneratedOtp(null);
        navigate("/");
      } else {
        setError("Error verifying OTP");
        setGeneratedOtp(null);
      }
    } catch (error) {
      setError("Error verifying OTP: " + error.message);
      console.error("Error verifying OTP: ", error);
    }
  };

  return (
    <>
      <SectionHero heading={"Sign Up"} />
      {error && <p>{error}</p>}
      <div className="sign-in">
        <div className="container">
          <div className="row justify-center">
            <div className="col-12 col-md-8">
              {!verificationId ? (
                <form onSubmit={handleSignUp}>
                  <div className="col-12">
                    <label htmlFor="number">Mobile No *</label>
                    <input
                      type="tel"
                      name="number"
                      id="number"
                      placeholder="Enter Your Mobile No..."
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                      maxLength={10}
                    />
                  </div>
                  <div className="col-12">
                    <button type="submit" className="button-basic orange">
                      Generate Random Number
                    </button>
                    <div ref={recaptchaContainerRef}></div>
                  </div>
                </form>
              ) : (
                <form onSubmit={handleOtpSubmit}>
                  <div className="col-12">
                    <label htmlFor="otp">Enter Random Number *</label>
                    <input
                      type="text"
                      name="otp"
                      id="otp"
                      placeholder="Enter Random Number..."
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </div>
                  <div
                    className="col-2"
                    onClick={handleOtpResend}
                    style={{
                      pointer: "cursor-pointer",
                      display: "flex",
                      position: "relative",
                    }}
                  >
                    <input
                      type="text"
                      name="otp"
                      id="otp"
                      disabled
                      value={generatedOtp}
                      style={{}}
                    />

                    <BsArrowRepeat
                      style={{
                        position: "absolute",
                        right: "10",
                        top: "12",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <button type="submit" className="button-basic orange">
                      Verify Yor Random Number
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
